.product-item {
  --image-size: calc(var(--ts-app-offset) * 15);

  border: solid 0.2rem var(--ts-app-color-input-bg);
  border-radius: var(--ts-app-border-radius);
  background: var(--ts-app-color-input-bg);
  padding: var(--ts-app-offset-l) var(--ts-app-offset);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--ts-app-gap);
  font-weight: var(--ts-app-font-weight-bold);
  text-transform: uppercase;

  .product-image {
    width: var(--image-size);
    height: var(--image-size);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 6.4rem;
      color: var(--ts-app-color-primary);
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }

  &.active,
  &:hover {
    border-color: var(--ts-app-color-primary);
  }

  .btn {
    margin-top: auto;
  }

  @container (max-width: 500px) {
    .btn {
      font-size: 1.2rem;
    }
  }
}
