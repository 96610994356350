.product-list {
  //--product-list-p: calc(var(--ts-app-offset-l) * -1);
  //--product-list-pr: calc(var(--ts-app-offset-l) * -0.5);
  //
  //display: flex;
  //flex-wrap: wrap;
  //gap: var(--ts-app-gap);
  //padding: var(--ts-app-offset-l);
  //
  //& > * {
  //  flex: 1 1 30%;
  //}

  &.scrollbar {
    margin-left: var(--product-list-p);
    margin-right: var(--product-list-pr);
    margin-bottom: var(--product-list-pr);
    //height: 60vh;
    height: 60rem;
    overflow-y: scroll;
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 0.5rem;
      background: var(--ts-app-color-bg);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--ts-app-color-primary);
      -webkit-border-radius: 1rem;
      -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }

    &::-webkit-scrollbar-corner {
      background: var(--ts-app-color-bg);
    }
  }
}


.manufacturer-list {
  //--product-list-p: calc(var(--ts-app-offset-l) * -1);
  //--product-list-pr: calc(var(--ts-app-offset-l) * -0.5);

  //display: flex;
  //flex-wrap: wrap;
  //gap: var(--ts-app-gap);
  //padding: var(--ts-app-offset-l);
  //container-type: inline-size;
  //
  //justify-content: flex-start;

  & > * {
    flex: 0;
    min-width: calc(33.3333333% - var(--ts-app-gap));
  }

  hr {
    background: var(--ts-app-color-border);
    height: 0.1rem;
    flex: 1 0 100%;
    width: 100%;
  }

  img {
    max-height: 2.4rem;
    width: auto;
  }
}

.product-list,
.manufacturer-list {
  --product-list-p: calc(var(--ts-app-offset-l) * -1);
  --product-list-pr: calc(var(--ts-app-offset-l) * -0.5);

  display: flex;
  flex-wrap: wrap;
  gap: var(--ts-app-gap);
  padding: var(--ts-app-offset-l);
  container-type: inline-size;

  justify-content: flex-start;

  & > * {
    flex: 0;
    min-width: calc(33.3333333% - var(--ts-app-gap));
  }
}

@container (max-width: 500px) {
  .product-list,
  .manufacturer-list {
    & > * {
      min-width: calc(51.3333333% - var(--ts-app-gap));
    }
  }

  .product-list-rent {
    padding-right: 0;
  }
}

//@container (max-width: 420px) {
//  .product-list,
//  .manufacturer-list {
//    & > * {
//      min-width: 13rem;
//      flex-grow: 1;
//    }
//  }
//}

.manufacturer-group-container {
  --product-list-p: calc(var(--ts-app-offset-l) * -1);
  --product-list-pr: calc(var(--ts-app-offset-l) * -0.5);

  margin-left: var(--product-list-p);
  margin-right: var(--product-list-pr);
  margin-bottom: var(--product-list-pr);
  //height: 60vh;
  height: 60rem;
  overflow-y: scroll;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.5rem;
    background: var(--ts-app-color-bg);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ts-app-color-primary);
    -webkit-border-radius: 1rem;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  &::-webkit-scrollbar-corner {
    background: var(--ts-app-color-bg);
  }
}

.manufacturer-group {
  .manufacturer-group-head {
    background: var(--ts-app-color-box-bg);
    color: white;
    padding: var(--ts-app-offset) var(--ts-app-offset-l) var(--ts-app-offset-s);
    position: sticky;
    top: -0.2rem;
    z-index: 100;
    font-size: var(--ts-app-font-size-l);
    font-weight: var(--ts-app-font-weight-bold);
    text-transform: uppercase;

    & + .product-list {
      padding-top: 0;
    }

    img {
      max-height: 2.4rem;
      width: auto;
    }

    span {
      position: relative;
      display: inline-block;
      background: var(--ts-app-color-box-bg);
      z-index: 100;
      padding-right: var(--ts-app-offset-l);
    }

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 0.2rem;
      background: var(--ts-app-color-border);
      left: var(--ts-app-offset-l);
      right: var(--ts-app-offset-l);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.manufacturer-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--ts-app-border-radius);
  padding: var(--ts-app-offset-xl) var(--ts-app-offset);
  border: solid 0.2rem transparent;
  gap: var(--ts-app-offset);

  svg {
    width: 2rem;
    position: relative;
    top: -0.2rem;
  }

  &:hover {
    border-color: var(--ts-app-color-primary);
  }
}

