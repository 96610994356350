.date-icon {
  overflow: hidden;
  border-radius: var(--ts-app-border-radius);
  background: var(--ts-app-color-box-shadow);
  box-shadow: var(--ts-app-box-shadow);
  text-align: center;
  width: 5.2rem;
  height: 5.2rem;
  line-height: 1;
  display: flex;
  flex-direction: column;

  .month {
    background: var(--ts-app-color-primary);
    color: var(--ts-app-color-button-base-text);
    text-transform: uppercase;
    padding: 0.45rem 0 0.2rem;
  }

  .day {
    font-size: var(--ts-app-font-size-xl);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.2rem;
  }
}
