.service-card-warp {
  display: flex;
  gap: var(--ts-app-gap);

  @media screen and (max-width: 726px) {
    flex-direction: column;
    gap: calc(var(--ts-app-gap) * 2);
  }
}

.service-card {
  --service-card-padding: var(--ts-app-offset-l);
  --service-icon-dim: 10rem;

  //background: var(--ts-app-color-box-bg);
  flex: 1;
  //overflow: hidden;
  cursor: pointer;
  position: relative;

  .service-card-hint {
    position: absolute;
    left: var(--ts-app-gap);
    right: var(--ts-app-gap);
    top: 0;
    z-index: 100;
    text-align: center;
    transform: translateY(-50%);

    span {
      line-height: 1;
      display: block;
      background: var(--ts-app-color-base);
      color: var(--ts-app-color-primary);
      padding: calc(var(--ts-app-offset-s) + 0.2rem) var(--ts-app-offset-l) var(--ts-app-offset-s);
      border-radius: calc(var(--ts-app-border-radius) * 4);
    }
  }

  .service-icon {
    width: var(--service-icon-dim);
    height: var(--service-icon-dim);
    filter: drop-shadow(0px 1px 1px 0px rgba(255, 255, 255, 0.45));
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 60%;
      height: auto;
    }
  }

  .service-title {
    font-weight: var(--ts-app-font-weight-bold);
    font-size: var(--ts-app-font-size-xl);
  }

  .service-content {
    //--ts-app-border-radius: 10rem;

    background: var(--ts-app-color-box-bg);
    padding: var(--service-card-padding);
    padding-top: var(--ts-app-offset-xxl);
    padding-bottom: var(--ts-app-offset-xl);
    display: flex;
    flex-direction: column;
    gap: var(--ts-app-gap);
    align-items: center;
    text-align: center;
    position: relative;
    border-radius: var(--ts-app-border-radius-l) var(--ts-app-border-radius-l) 0 0;
    overflow: hidden;

    & > * {
      position: relative;
      z-index: 2;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      z-index: 1;
    }
  }

  .service-footer {
    background: var(--ts-app-color-primary);
    padding: var(--service-card-padding);
    text-align: center;
    font-weight: var(--ts-app-font-weight-medium);
    border-radius: 0 0 var(--ts-app-border-radius-l) var(--ts-app-border-radius-l);
    overflow: hidden;
  }

  &:hover {
    .service-footer {
      background: var(--ts-app-color-primary-hover);
    }
  }
}
