.manufacturer-count-item {
  display: flex;
  background: var(--ts-app-color-box-shadow);
  border-radius: var(--ts-app-border-radius);
  font-size: var(--ts-app-font-size-l);
  border: solid 1px var(--ts-app-color-input-bg);

  & > * {
    display: flex;
    align-items: center;
    padding: calc(var(--ts-app-offset) * 1.25) calc(var(--ts-app-offset) * 1.5);
    padding-top: calc(var(--ts-app-offset) * 1.5);
    position: relative;
    min-height: 4.8rem;
  }

  .number {
    flex-shrink: 0;
    margin-left: auto;
    border-left: solid 1px var(--ts-app-color-input-bg);
  }

  .title {
    flex-grow: 1;
  }

  img {
    --inner-gap: 1rem;

    position: absolute;
    object-fit: contain;
    object-position: left;
    left: var(--inner-gap);
    top: var(--inner-gap);
    width: calc(100% - calc(var(--inner-gap) * 2));
    height: calc(100% - calc(var(--inner-gap) * 2));
    max-width: 15rem;
    max-height: 3rem;
  }
}

.manufacturer-selection {
  display: flex;
  flex-wrap: wrap;
  gap: var(--ts-app-gap);
  margin-bottom: var(--ts-app-gap);
  line-height: 1;

  & > * {
    border-radius: var(--ts-app-border-radius);
    background: var(--ts-app-color-box-shadow);
    border: solid 1px var(--ts-app-color-input-border);
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    & > * {
      padding: var(--ts-app-offset) calc(var(--ts-app-offset) * 1.5);
      display: flex;

      &:first-child {
        border-right: solid 1px var(--ts-app-color-input-border);
      }
    }
  }
}

.manufacturer-count-item-info {
  color: var(--ts-app-color-error-light);
  position: absolute;
  padding-top: calc(var(--ts-app-offset) / 4);
  padding-left: calc(var(--ts-app-offset) / 1);
}
