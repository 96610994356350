.card-prodct-row {
  //display: flex;
  //align-items: center;

  display: grid;
  grid-template-columns: auto 1fr auto auto;
  gap: var(--ts-app-offset);
  padding-top: var(--ts-app-offset-s);
  padding-bottom: var(--ts-app-offset-s);

  select {
    background: var(--ts-app-color-cart-select-bg);
    border: none;
    padding: 0.3rem 0.1rem;
    border-radius: var(--ts-app-border-radius);
  }
}

.card-prodct-row-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--ts-app-offset-s);
}

.card-prodct-row-info-warp {
  overflow: hidden;
}

.card-prodct-row-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--ts-app-offset-s);

  .name {
    flex-grow: 1;
    //overflow: hidden;
    //white-space: nowrap;
    //text-overflow: ellipsis;
    min-width: 0;
  }

  .image {
    flex: 0 0 4.5rem;
    max-height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--ts-app-color-overlay-bg);
    padding: var(--ts-app-offset-s);
    border-radius: var(--ts-app-border-radius);
    color: var(--ts-app-color-primary);

    .product-image {
      display: flex;
    }

    svg,
    img {
      width: 100%;
      height: auto;
    }

    svg {
      max-width: 2.6rem;
    }
  }
}

.card-prodct-row-price {
  display: flex;
  align-items: center;
}

.cart-row .cart-row-inner.cart-row-inner-rent {
  .amount {
    flex-basis: 5rem;
  }
}
