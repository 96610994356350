.styled-input {
  --styled-input-mb: calc(var(--ts-app-offset) * 2.25);
  --styled-input-label-pb: calc(var(--ts-app-offset) / 1.25);
  --styled-input-form-p: calc(var(--ts-app-offset) * 1.5) calc(var(--ts-app-offset) * 2);
  --styled-input-border-radius: var(--ts-app-border-radius-l);

  margin-bottom: var(--styled-input-mb);

  &.has-error {
    --ts-app-color-input-border: var(--ts-app-color-error);

    .input-number-wrap,
    input:not([type="range"]):not([type="number"]),
    button:not(.input-number-wrap button):not(.react-datepicker button),
    select,
    textarea {
      outline: 2px solid var(--ts-app-color-error);
    }
  }

  &.styled-input-input-range {
    display: flex;
    flex-direction: column;

    .item-box {
      flex: 1;
      display: flex;
    }
  }

  label {
    display: block;
    padding-bottom: var(--styled-input-label-pb);

    &[for] {
      cursor: pointer;
    }
  }

  &.inline {
    display: flex;
    align-items: center;
    gap: var(--ts-app-gap);
    container-type: inline-size;

    @media screen and (max-width: 400px) {
      //flex-wrap: wrap;
      flex-direction: column;
    }

    input {
      flex: 0 0 10rem;
    }

    label {
      flex: 1 1 auto;
      padding-bottom: 0;
    }

    @container (max-width: 350px) {
      //flex-wrap: wrap;
      //flex-direction: column;

      & > * {
        flex-basis: 100%;
        width: 100%;

        &.input-number-wrap {
          justify-content: center;
        }
      }
    }
  }

  &.max-w {
    max-width: 7.25rem;
  }

  .input-number-wrap,
  input:not([type="range"]):not([type="number"]),
  button:not(.input-number-wrap button):not(.react-datepicker button),
  select,
  textarea {
    font-family: var(--ts-app-font-family);
    font-size: var(--ts-app-font-size-base);
    border-radius: var(--styled-input-border-radius);
    border: 1px solid var(--ts-app-color-input-border);
    background: var(--ts-app-color-input-bg);
    padding: var(--styled-input-form-p);
    width: 100%;
    color: var(--ts-app-color-base);
  }

  select {
    padding-left: calc(var(--ts-app-offset) * 1.5);
    padding-right: 0;
  }

  textarea {
    min-height: 13.5rem;
  }

  .styled-input-wrap {
    display: flex;
    gap: var(--ts-app-offset);

    & > * {
      flex: 1;

      &.size-xs {
        flex-basis: 30%;
        flex-grow: 0;
      }
    }

    .styled-input {
      margin-bottom: 0;
    }
  }

  .buttons-wrap {
    display: flex;
    gap: var(--ts-app-gap);

    & > * {
      flex: 1;
    }
  }

  button {
    text-align: center;
    width: auto;
    cursor: pointer;

    &.active {
        background: var(--ts-app-color-primary) !important;
        border-color: var(--ts-app-color-primary) !important;
        color: var(--ts-app-color-button-base-text !important);
    }
  }
}

.input-container-title {
  font-size: var(--ts-app-font-size-l);
  padding-bottom: calc(var(--ts-app-offset) / 1.25);
}

.input-container-info {
  text-align: center;
  color: var(--ts-app-color-primary);
  font-size: var(--ts-app-font-size-m);
}

.input-container {
  --input-container-p: calc(var(--ts-app-offset) * 1.5) calc(var(--ts-app-offset) * 2);
  --styled-input-mb: calc(var(--ts-app-offset) * 2.25);

  margin-bottom: var(--styled-input-mb);

  border-radius: var(--ts-app-border-radius-xl);
  border: 0.05rem solid var(--ts-app-color-input-border);
  background: var(--ts-app-color-box-header-bg);
  box-shadow: 0px 1px 1px 0px rgba(163, 163, 163, 0.35) inset;
  padding: var(--input-container-p);
}

.container-row {
  container-type: inline-size;
}

@container (min-width: 500px) {
  .input-container-accessories {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: var(--ts-app-offset-xl);

    & > * {
      flex: 1 1 45%;
      margin-bottom: 0;

      &:nth-child(1),
      &:nth-child(2) {
        padding-bottom: var(--ts-app-gap);
      }

      &:nth-child(2n) {
        padding-left: var(--ts-app-offset-xl);
        border-left: solid 1px var(--ts-app-color-border);
      }
    }
  }
}

.item-box:not(.unstyled) {
  --input-box-p: calc(var(--ts-app-offset) * 1.5) calc(var(--ts-app-offset) * 2);

  border-radius: var(--ts-app-border-radius-xl);
  background: var(--ts-app-color-input-bg);
  box-shadow: 0px 1px 1px 0px rgba(163, 163, 163, 0.25) inset;
  padding: var(--input-box-p);

  .styled-input {
    margin-bottom: 0;
  }

  &.hint {
    border: 0.05rem solid var(--ts-app-color-primary);
    box-shadow: none;
    background: none;
    height: 100%;
    display: flex;
    align-items: center;
    gap: var(--ts-app-offset);
    font-size: var(--ts-app-font-size-m);

    img {
      width: 12rem;
      height: auto;
    }
  }
}

.form-grid {
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--ts-app-gap);

  & > * {
    flex: 1 0 45%;

    @media screen and (max-width: 726px) {
      flex: 1 0 100%;
    }
  }

  &.fix-gap {
    margin-bottom: var(--ts-app-gap);

    @media screen and (max-width: 726px) {
      row-gap: var(--ts-app-gap);
    }
  }
}

.input-number-wrap {
  display: flex;
  flex: 0;
  margin-left: auto;
  //padding-left: calc(var(--styled-input-form-p) / 2) !important;
  //padding-right: calc(var(--styled-input-form-p) / 2) !important;

  button,
  input {
    border: none;
    background: none;
    color: var(--ts-app-color-base);
    font-family: var(--ts-app-font-family);
    font-size: var(--ts-app-font-size-base);
  }

  button {
    font-size: var(--ts-app-font-size-l);
    color: var(--ts-app-color-primary);
    line-height: 1;
    cursor: pointer;
    //padding-left: 0;
    //padding-right: 0;
    //width: 5rem;
  }

  input {
    flex: none !important;
    text-align: center;
    width: 5rem;

    &:focus {
      outline: none;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type=number] {
      -moz-appearance: textfield;
    }
  }

  &.input-number-small {
    position: relative;

    button {
      position: absolute;
      right: 0;
      background: var(--ts-app-color-black);
      height: calc(var(--ts-app-offset) * 2.25);
      width: calc(var(--ts-app-offset-l) * 1.25);
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      top: 0;

      &.btn-up {
        transform: translateY(-50%);
      }

      &.btn-down {
        margin-top: 1px;
        transform: translateY(50%);
      }
    }

    input {
      padding-right: calc(var(--ts-app-offset-l) * 1.75);
      width: 6rem;
    }
  }
}

.input-range-wrap {
  display: flex;
  align-items: center;
  width: 100%;
  gap: var(--ts-app-offset);

  input[type="range"] {
    flex: 1;
  }

  div {
    flex: 0;
    padding-top: 0.2rem;
  }
}

.styled-input-datepicker {
  --styled-input-mb: calc(var(--ts-app-offset) * 2.25);
  --styled-input-label-pb: calc(var(--ts-app-offset) / 1.25);
  --styled-input-form-p: calc(var(--ts-app-offset) * 1.5) calc(var(--ts-app-offset) * 2);
  --styled-input-border-radius: var(--ts-app-border-radius-l);

  .has-error-input {
    --ts-app-color-input-border: var(--ts-app-color-error);

    outline: 2px solid var(--ts-app-color-error);
  }

  margin-bottom: var(--styled-input-mb);

  & > label {
    display: block;
    padding-bottom: var(--styled-input-label-pb);

    &[for] {
      cursor: pointer;
    }
  }

  .styled-input-datepicker-input-wrap {
    position: relative;

    svg {
      position: absolute;
      color: var(--ts-app-color-primary);
      z-index: 1;
      left: var(--ts-app-offset);
      top: 50%;
      height: 2rem;
      transform: translateY(-50%);
    }
  }

  .react-datepicker-wrapper {
    display: block;
  }

  input[type="text"] {
    font-family: var(--ts-app-font-family);
    font-size: var(--ts-app-font-size-base);
    border-radius: var(--styled-input-border-radius);
    border: 1px solid var(--ts-app-color-input-border);
    background: var(--ts-app-color-input-bg);
    padding: var(--styled-input-form-p);
    padding-left: calc(var(--ts-app-offset) * 4);
    width: 100%;
    color: var(--ts-app-color-base);
  }
}
