.cart-row {
  display: flex;
  flex-direction: column;

  & + .cart-row {
    border-top: solid 1px var(--app-color-border, var(--ts-app-color-border));
    padding-top: var(--ts-app-offset);
  }

  .cart-row-inner {
    display: flex;
    padding: var(--ts-app-offset-s) 0;
    gap: var(--ts-app-gap);

    &.cart-row-inner-head {
      padding: var(--ts-app-offset) 0;
      font-variant: all-small-caps;
      font-size: var(--ts-app-font-size-m);
    }

    &.small {
      padding-top: 0;

      .name {
        font-size: var(--ts-app-font-size-m);
        white-space: normal;
      }
    }

    .amount {
      flex: 0 0 3rem;
      text-align: center;
      white-space: nowrap;
    }

    .name {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .currency {
      flex: 0 0 5.5rem;
      text-align: right;
      margin-left: auto;
      white-space: nowrap;

      &.free {
        color: var(--ts-app-color-primary);
      }
    }
  }
}

.cart-head {
  border-bottom: solid 1px var(--app-color-border, var(--ts-app-color-border));
  font-weight: var(--ts-app-font-weight-bold);
  text-transform: uppercase;
  min-height: var(--ts-app-offset-xl);
  padding-bottom: var(--ts-app-offset);
}

.cart-head-normal {
  border-bottom: solid 1px var(--app-color-border, var(--ts-app-color-border));
  min-height: var(--ts-app-offset-xl);
  padding-bottom: var(--ts-app-offset);
  font-size: var(--ts-app-font-size-l);
}
