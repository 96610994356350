.content-navigation {
  display: flex;
  gap: var(--ts-app-gap);
  align-items: center;
  padding-bottom: var(--ts-app-offset-l);
  justify-content: center;

  .nav-left {
    margin-right: auto;
  }

  .nav-right {
    margin-left: auto;
    display: flex;
    gap: var(--ts-app-gap);

    @media screen and (max-width: 726px) {
      & > *:first-child {
        display: none;
      }
    }
  }
}
