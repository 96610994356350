.filter-wrap {
  display: flex;
  gap: var(--ts-app-gap);
  margin-bottom: var(--ts-app-gap);
  flex-wrap: wrap;
  container-type: inline-size;
  position: relative;
  z-index: 100;

  & > * {
    flex: 1 1 45%;
    min-width: 25rem;
  }

  &.filter-wrap-rent {
    & > * {
      flex: 1 1 30%;
      min-width: 12rem;
    }
  }
}

@container (min-width: 580px) {
  .filter-wrap {
    & > * {
      flex: 1 1 20%;
      min-width: auto;
    }
  }
}

.filter-item {
  label {
    display: block;
    padding-bottom: var(--ts-app-offset-s);
  }

  &.filter-item-disabled {
    opacity: 0.5;

    label {
      opacity: 0.5;
    }
  }

  input,
  select {
    width: 100%;
    color: var(--ts-app-color-button-base-text);
    border-radius: var(--ts-app-border-radius);
    border: 0.05rem solid var(--ts-app-color-input-filter-border);
    background: var(--ts-app-color-box-bg);
    padding: var(--ts-app-offset);
  }

  .filter-item-serach {
    position: relative;

    .react-datepicker-wrapper {
      width: 100%;
    }

    input {
      padding-left: calc(var(--ts-app-offset-l) * 1.5);
    }

    svg {
      position: absolute;
      color: var(--ts-app-color-primary);
      height: var(--ts-app-offset-l);
      width: auto;
      top: 50%;
      left: var(--ts-app-offset-s);
      transform: translateY(-50%);
      z-index: 1;
    }

    &.disabled {
      opacity: 0.5;

      svg {
        opacity: 0.25;
      }
    }
  }
}

.filter-reset {
  text-align: right;
  margin-top: calc(var(--ts-app-gap) * -0.5);
  padding-bottom: calc(var(--ts-app-gap) * 0.5);

  button {
    font-size: var(--ts-app-font-size-m);

    &.disabled {
      cursor: default;
      color: var(--ts-app-color-input-filter-border);
    }
  }
}
