.headlines {
  --headlines-pb: calc(var(--ts-app-offset) * 6);
  --headlines-back-height: calc(var(--ts-app-font-size-base) * 1.5);

  padding-bottom: var(--headlines-pb);

  &.no-back {
    padding-top: var(--headlines-back-height);
  }

  h1 {
    font-weight: var(--ts-app-font-weight-bold);
    font-size: var(--ts-app-font-size-xl);
    padding-bottom: var(--ts-app-offset);
    text-transform: uppercase;
  }

  .headlines-back {
    display: flex;
    color: var(--ts-app-color-primary);
    text-transform: uppercase;
    padding-bottom: var(--ts-app-offset);
    font-weight: var(--ts-app-font-weight-bold);
    letter-spacing: 0.07rem;
    cursor: pointer;
    height: var(--headlines-back-height);

    &:hover {
      color: var(--ts-app-color-primary-hover);
    }

    svg {
      --icon-dim: var(--ts-app-font-size-base);

      height: var(--icon-dim);
      width: var(--icon-dim);
    }
  }
}
