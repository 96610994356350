.service-cards-info {
  padding-top: var(--ts-app-offset-l);
  text-align: center;
  font-size: var(--ts-app-font-size-m);
}

.service-cards-legal {
  font-size: var(--ts-app-font-size-m);
  margin-top: var(--ts-app-offset-xl);
  padding-top: var(--ts-app-offset-l);
  border-top: solid 0.1rem var(--ts-app-color-border);
}
