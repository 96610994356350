.CircularProgressbar {
  width: 100%;
  vertical-align: middle;

  .CircularProgressbar-path {
    stroke: var(--ts-app-color-primary);
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }

  .CircularProgressbar-trail {
    fill: var(--ts-app-color-bg);
    stroke-linecap: round;
  }

  .CircularProgressbar-text {
    fill: var(--ts-app-color-primary);
    font-size: 20px;
    dominant-baseline: middle;
    text-anchor: middle;
  }

  .CircularProgressbar-background {
    fill: var(--ts-app-color-bg);
  }
}

.progressbar-wrapper {
  position: relative;
  width: 8rem;
  height: 8rem;

  .progressbar-overlay {
    $inset: 13%;

    box-shadow: 0px 1px 1px 0px rgba(255, 255, 255, 0.25) inset;
    color: var(--ts-app-color-primary);
    position: absolute;
    left: $inset;
    right: $inset;
    bottom: $inset;
    top: $inset;
    background: var(--ts-app-color-bg);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    overflow: hidden;

    & > .service-provider-logo {
      box-shadow: none;
      background: none;
    }
  }
}

.order-confirmation-container {
  min-height: 60rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: calc(var(--ts-app-gap) * 1.5);
  text-align: center;
  padding: 0 calc(var(--ts-app-offset) * 4);

  strong {
    font-size: var(--ts-app-font-size-l);
  }

  .confirm-content {
    display: flex;
    flex-direction: column;
    gap: var(--ts-app-offset);
    margin-bottom: calc(var(--ts-app-gap) * 1);
  }

  .confirm-info {
    color: var(--ts-app-color-base-light);
  }
}
