.product-info-card {
  --img-size: calc(var(--ts-app-offset) * 5);

  display: flex;
  gap: var(--ts-app-offset);
  align-items: center;
  font-weight: var(--ts-app-font-weight-bold);
  text-transform: uppercase;

  .product-image {
    width: var(--img-size);
    height: var(--img-size);
    border-radius: var(--ts-app-border-radius);
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--ts-app-color-input-bg);

    svg {
      width: 2rem;
      height: auto;
      color: var(--ts-app-color-primary);
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
    }
  }
}

.item-box {
  .product-info-card {
    .product-image {
      background: var(--ts-app-color-box-bg);
    }
  }
}
