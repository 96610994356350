@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-Black.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-BlackCondensed.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-BlackCondensed.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-BlackItalic.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-Bold.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-BoldCondensed.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-BoldCondensed.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-BoldItalic.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-Heavy.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-Heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-HeavyCondensed.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-HeavyCondensed.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-Condensed.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-Condensed.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-Italic.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-HeavyItalic.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-HeavyItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-LightItalic.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-LightCondensed.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-LightCondensed.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-Light.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-Regular.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-MediumItalic.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-MediumCond.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-MediumCond.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-Medium.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-UltraLightIt.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-UltraLightIt.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-UltraLight.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-UltraLight.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'DIN Next LT Pro';
  src: url('../fonts/dinnext/DINNextLTPro-UltraLightCond.woff2') format('woff2'),
  url('../fonts/dinnext/DINNextLTPro-UltraLightCond.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
