.manufacturer-list-select {
  //display: flex;
  //flex-wrap: wrap;
  gap: var(--ts-app-gap);
  container-type: inline-size;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
  }

  &:has(.manufacturer-count-item-info) {
    row-gap: calc(var(--ts-app-gap) * 1.5);
  }

  //& > * {
  //  flex-grow: 1;
  //  flex-shrink: 0;
  //  flex-basis: 40%;
  //
  //  @container (max-width: 450px) {
  //    flex-basis: 100%;
  //  }
  //}
}
