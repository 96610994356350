.appointment-item {
  display: flex;
  gap: var(--ts-app-gap);
  background: var(--ts-app-color-input-bg);
  padding: calc(var(--ts-app-offset) * 2);
  align-items: center;
  border-radius: var(--ts-app-border-radius);
  container-type: inline-size;
  position: relative;

  label {
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.clickable {
    cursor: pointer;
  }

  &:hover {
    box-shadow: var(--ts-app-box-shadow);
  }

  .action-icon {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    gap: 0.25rem;
    width: 6rem;
    color: var(--ts-app-color-primary);
    font-style: italic;
    font-size: var(--ts-app-font-size-m);

    svg {
      height: auto;
      width: 4rem;
      color: var(--ts-app-color-primary);
    }

    @container (max-width: 400px) {
      width: 3rem;

      svg {
        width: 2rem;
      }
    }
  }

  &.has-bell {
    .action-icon {
      align-items: center;

      svg {
        width: 2rem;
      }
    }
  }
}

.appointment-item-info {
  flex: 1;
  display: flex;
  flex-direction: column;

  .title {
    font-size: var(--ts-app-font-size-l);
    font-weight: var(--ts-app-font-weight-bold);
    text-transform: uppercase;
  }

  .meta {
    display: flex;
    flex-wrap: wrap;
    gap: var(--ts-app-gap);
    row-gap: var(--ts-app-offset);
    align-items: center;

    & > * {
      display: flex;
      align-items: center;
      gap: var(--ts-app-offset-s);
    }
  }

  svg {
    height: 1.6rem;
    width: auto;
  }
}
