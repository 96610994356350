.btn {
  --button-bg: var(--ts-app-color-primary);
  --button-bg-hover: var(--ts-app-color-primary-hover);
  --button-bg-ghost: var(--ts-app-color-box-bg);
  --button-color: var(--ts-app-color-button-base-text);
  --button-color-hover: var(--ts-app-color-button-base-text);
  --button-padding-y: calc(var(--ts-app-offset) * 1);
  --button-padding-x: calc(var(--ts-app-offset) * 2);
  --button-font-size: var(--ts-app-font-size-base);

  --button-icon-padding: calc(var(--button-padding-x) + calc(var(--ts-app-offset) * 1.5 ));

  border: none;
  white-space: nowrap;
  font-family: var(--ts-app-font-family);
  background: var(--button-bg);
  line-height: 1.2;
  color: var(--button-color);
  border-radius: var(--ts-app-border-radius);
  font-size: var(--button-font-size);
  font-weight: var(--ts-app-font-weight-medium);
  display: flex;
  padding: calc(var(--button-padding-y) * 1.25) var(--button-padding-x)  var(--button-padding-y);
  position: relative;
  cursor: pointer;

  &.btn-ghost {
    background: var(--button-bg-ghost);
    border: solid 1px var(--ts-app-color-primary-hover);
  }

  &.btn-secondary {
    --button-bg: var(--ts-app-color-secondary);
    --button-bg-hover: var(--ts-app-color-secondary-hover);
  }

  &.btn-disabled,
  &[disabled] {
    --button-bg: var(--ts-app-color-border);

    &:hover {
      background: var(--button-bg) !important;
    }

    cursor: not-allowed;
  }

  &.inactive {
    background: none;
  }

  &.btn-l {
    --button-padding-y: calc(var(--ts-app-offset) * 1.25);
    --button-padding-x: calc(var(--ts-app-offset) * 2.5);
    --button-font-size: 1.8rem;
  }

  &.btn-xl {
    --button-padding-y: calc(var(--ts-app-offset) * 1.5);
    --button-padding-x: calc(var(--ts-app-offset) * 2.75);
    --button-font-size: 2rem;
  }

  &.btn-dark {
    --button-bg: var(--ts-app-color-box-bg);
    --button-color: var(--ts-app-color-primary);
    --button-color-hover: var(--ts-app-color-button-base-text);
  }

  &.btn-dark-desktop {
    @container (min-width: 481px) {
      --button-bg: var(--ts-app-color-box-bg);
      --button-color: var(--ts-app-color-primary);
      --button-color-hover: var(--ts-app-color-button-base-text);
    }
  }

  &.btn-full {
    width: 100%;
  }

  svg {
    position: absolute;
    right: calc(var(--button-padding-x) / 4);
    height: 60%;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
  }

  &.btn--right-icon {
    padding-right: var(--button-icon-padding);
  }

  &.btn--left-icon {
    padding-left: var(--button-icon-padding);

    svg {
      right: auto;
      left: calc(var(--button-padding-x) / 4);
    }

    &.btn--icon-plus {
      padding-left: calc(var(--button-icon-padding) * 1.25);

      svg {
        height: 48%;
        left: calc(var(--button-padding-x) / 1.5);
      }
    }
  }

  &.btn--icon-arrow-right {
    svg {
      height: 40%;
      right: calc(var(--button-padding-x) / 2);
    }
  }

  &.btn--icon-question {
    &.btn--left-icon {
      svg {
        height: 40%;
        left: calc(var(--button-padding-x) / 2);
      }
    }
  }

  &:hover {
    background: var(--button-bg-hover);
    color: var(--button-color-hover);
  }
}

.tab-navi-wrap {
  text-align: center;
  margin-bottom: var(--ts-app-offset-l);
}

.tab-navi {
  border-radius: var(--ts-app-border-radius-l);
  border: 0.05rem solid var(--ts-app-color-input-border);
  background: var(--ts-app-color-input-bg);
  box-shadow: 0px 1px 1px 0px rgba(163, 163, 163, 0.25) inset;
  display: inline-flex;
  gap: var(--ts-app-offset-s);
  justify-content: center;
  padding: var(--ts-app-offset-s);
  margin: 0 auto;

  &.tab-navi-wrap {
    flex-wrap: wrap;
  }

  .btn {
    padding: var(--ts-app-offset-s) var(--ts-app-offset);
  }
}

.btn--reset {
  background: none;
  border: none;
  color: var(--ts-app-color-primary);
  padding: 0;
  font-size: 1.6rem;
  cursor: pointer;
}

.delete-btn {
  color: var(--ts-app-color-primary);
  transition: .2s;

  &:hover {
    color: var(--ts-app-color-error-light);
  }

  svg {
    position: relative;
    top: 0.2rem;
    height: 2rem;
    width: auto;
  }
}
