.appointment-item-list {
  display: flex;
  flex-direction: column;
  gap: var(--ts-app-gap);

  padding-right: var(--ts-app-offset-l);
  height: 60rem;
  overflow-y: scroll;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 0.5rem;
    background: var(--ts-app-color-bg);
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ts-app-color-primary);
    -webkit-border-radius: 1rem;
    -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
  }

  &::-webkit-scrollbar-corner {
    background: var(--ts-app-color-bg);
  }
}

.appointment-list-navi {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: var(--ts-app-gap);
  margin-bottom: var(--ts-app-gap);

  .tab-navi-wrap {
    margin-bottom: 0;
  }

  .filter-item {
    margin-left: auto;
    flex-shrink: 0;
    flex-grow: 1;
  }
}

.appointment-list-info {
  margin-bottom: var(--ts-app-gap);
}

.box-overlay {
  background: var(--ts-app-color-input-bg);
  padding: var(--ts-app-gap);
  border-radius: var(--ts-app-border-radius);

  .box-overlay-head {
    display: flex;
    margin-bottom: var(--ts-app-offset-l);

    .box-overlay-head-dealers {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      gap: var(--ts-app-offset);
    }

    .appointment-item {
      cursor: default;
      padding: 0;
      background: none;
      box-shadow: none !important;
      flex-grow: 1;
    }

    .box-overlay-close {
      flex-shrink: 0;
      margin-left: auto;
      cursor: pointer;
      color: var(--ts-app-color-primary);

      svg {
        width: 2rem;
        height: auto;
      }
    }
  }
}

.vos-intro {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ts-app-offset) * 1.5);
  padding: var(--ts-app-gap) calc(var(--ts-app-gap) * 1.5);

  @media screen and (max-width: 726px) {
    padding-left: 0;
    padding-right: 0;
  }

  .title {
    text-align: center;
    font-size: var(--ts-app-font-size-l);
    font-weight: var(--ts-app-font-weight-bold);
    text-transform: uppercase;
  }

  .filter-item .filter-item-serach {
    input {
      font-size: 1.6rem;
      padding: calc(var(--ts-app-offset) * 2) calc(var(--ts-app-offset) * 1.5);
      padding-left: calc(var(--ts-app-offset-l) * 2.25) !important;
    }

    svg {
      left: calc(var(--ts-app-offset) * 2);
    }
  }

  .vos-intro-info {
    text-align: center;
    font-size: var(--ts-app-font-size-m);
    display: inline-flex;
    align-items: center;
    justify-content: center;

    svg {
      color: var(--ts-app-color-primary);
      width: 2.2rem;
    }
  }
}

.vos-intro-no-text {
  display: flex;
  flex-direction: column;
  gap: calc(var(--ts-app-offset) * 1.5);
  justify-content: center;
  align-items: center;
  padding: var(--ts-app-offset) 0;

  img {
    width: 100%;
    max-width: 26rem;
    height: auto;
  }
}
