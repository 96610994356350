@import "css/_reset.css";
@import "css/_fonts.css";

:root {
  --ts-app-color-black: var(--app-color-black, #000);
  --ts-app-color-primary: var(--app-color-primary, #1989D9);
  --ts-app-color-primary-hover: var(--app-color-primary-hover, #1368A6);
  --ts-app-color-secondary: var(--app-color-secondary, #56af10);
  --ts-app-color-secondary-hover: var(--app-color-secondary-hover, #50a113);
  --ts-app-color-button-base-text: var(--app-color-button-base-text, #FFF);
  --ts-app-color-bg: var(--app-color-bg, #062B3B);
  --ts-app-color-box-bg: var(--app-color-box-bg, #232330);
  --ts-app-color-box-header-bg: var(--app-color-box-header-bg, #29293D);
  --ts-app-color-box-shadow: var(--app-color-box-shadow, #16161A);
  --ts-app-color-base: var(--app-color-base, #fff);
  --ts-app-color-base-light: var(--app-color-base, #979797);
  --ts-app-color-border: var(--app-border-color, #626262);
  --ts-app-color-input-bg: var(--app-color-input-bg, #2F2F45);
  --ts-app-color-input-border: var(--app-color-input-border, #535353);
  --ts-app-color-input-filter-border: var(--app-color-input-border, #474761);
  --ts-app-color-overlay-bg: var(--app-color-input-border, #1B1B27);
  --ts-app-color-error: var(--app-color-error, #7a2525);
  --ts-app-color-error-light: var(--app-color-error, #E93327);
  --ts-app-color-cart-select-bg: var(--app-color-error, #B4B4CF);

  --ts-app-offset: var(--app-offset, 0.8rem);
  --ts-app-offset-l: var(--app-offset-l, 2rem);
  --ts-app-offset-xl: var(--app-offset-xl, 3.5rem);
  --ts-app-offset-xxl: var(--app-offset-xxl, 5rem);
  --ts-app-offset-s: calc(var(--ts-app-offset) / 1.5);
  --ts-app-gap: calc(var(--ts-app-offset) * 2.5);
  --ts-app-border-radius: var(--app-border-radius, 0.5rem);
  --ts-app-border-radius-l: var(--app-border-radius-l, 0.7rem);
  --ts-app-border-radius-xl: var(--app-border-radius-xl, 1rem);
  --ts-app-box-shadow: var(--app-box-shadow, 0px 15px 30px -20px var(--ts-app-color-box-shadow));

  --ts-app-w-left: 52%;
  --ts-app-max-w-left: 65rem;
  --ts-app-w-right: 40%;
  --ts-app-max-w-right: 43rem;
  --ts-app-min-w-right: 43rem;
  --ts-app-container-max-w: 110rem;

  --ts-app-font-size-s: var(--app-font-size-s, 1rem);
  --ts-app-font-size-m: var(--app-font-size-m, 1.3rem);
  --ts-app-font-size-14: var(--app-font-size-m, 1.4rem);
  --ts-app-font-size-base: var(--app-font-size-base, 1.6rem);
  --ts-app-font-size-l: var(--app-font-size-l, 2rem);
  --ts-app-font-size-xl: var(--app-font-size-xl, 2.5rem);

  --ts-app-line-height: var(--app-line-height, 1.5);
  --ts-app-line-height-headlines: var(--app-line-height-headlines, 1.15);

  --ts-app-font-family: var(--app-font-family, "DIN Next LT Pro", sans-serif);
  --ts-app-font-weight-default: var(--app-font-weight-default, 400);
  --ts-app-font-weight-medium: var(--app-font-weight-medium, 500);
  --ts-app-font-weight-bold: var(--app-font-weight-bold, 700);
}

html {
  font-size: 62.5%; /* Sets up the Base 10 stuff */
  //font-size: 80%;
}

body {
  color: var(--ts-app-color-button-base-text);
  font-smooth: never;
  font-size: var(--ts-app-font-size-base);
  line-height: var(--ts-app-line-height);
  font-family: var(--ts-app-font-family);
}

html,
.app {
  background: var(--ts-app-color-bg);
}

.app {
  padding: var(--ts-app-offset);
}

.container {
  //background: yellow;
  max-width: var(--ts-app-container-max-w);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  row-gap: var(--ts-app-gap);
  padding: 0 var(--ts-app-offset);
  container-type: inline-size;

  & > * {
    padding: 0 var(--ts-app-offset);
  }

  .left {
    //background: red;
    flex: 1 0 var(--ts-app-w-left);
    //max-width: var(--ts-app-max-w-left);
  }

  &.has-content-navi {
    .right {
      padding-top: calc(var(--ts-app-offset) * 7);
    }
  }

  .right {
    //background: green;
    flex: 1 0 var(--ts-app-w-right);
    min-width: var(--ts-app-min-w-right);
    overflow: hidden;

    & > div {
      position: sticky;
      //top: var(--ts-app-offset-l);
      top: 0;
    }

    @container (max-width: 450px) {
      min-width: 100%;
      padding-top: calc(var(--ts-app-offset) * 2) !important;
    }
  }
}

.box {
  background: var(--ts-app-color-box-bg);
  border-radius: var(--ts-app-border-radius-xl);
  box-shadow: var(--ts-app-box-shadow);

  .box-header {
    border-radius: var(--ts-app-border-radius) var(--ts-app-border-radius-xl) 0px 0px;
    background: var(--ts-app-color-box-header-bg);
    box-shadow: 0px 2px 2px -1px rgba(255, 255, 255, 0.35) inset, 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: var(--ts-app-offset-l);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: var(--ts-app-gap);
  }

  .box-header-right {
    margin-left: auto;
  }

  .box-content {
    padding: var(--ts-app-offset-l);
    position: relative;
  }

  .box-cta {
    display: flex;
    justify-content: flex-end;
    padding-top: var(--ts-app-gap);
    align-items: flex-start;
    gap: var(--ts-app-gap);

    .box-cta-hint {
      strong {
        display: block;
      }
    }
  }

  .box-alert {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding: var(--ts-app-offset-xl);
    display: flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: var(--ts-app-color-box-shadow);
      opacity: 0.8;
    }

    .box-alert-header {
      font-size: calc(var(--ts-app-font-size-xl) * 1.125);
      font-weight: var(--ts-app-font-weight-bold);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--ts-app-offset);
      line-height: 1;
    }

    .box-alert-footer {
      padding-top: var(--ts-app-offset-l);
      display: flex;
      flex-direction: column;
      gap: var(--ts-app-offset);

      .unkown-link {
        display: flex;
        justify-content: center;
      }
    }

    .box-alert-inner {
      background: var(--ts-app-color-box-header-bg);
      color: var(--ts-app-color-button-base-text);
      padding: var(--ts-app-offset-xl);
      border-radius: var(--ts-app-border-radius);
      display: flex;
      flex-direction: column;
      gap: var(--ts-app-offset);
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;
      z-index: 1;

      .box-alert-close {
        position: absolute;
        top: var(--ts-app-offset-l);
        right: var(--ts-app-offset-l);
        cursor: pointer;
        color: var(--ts-app-color-primary);
        z-index: 2;

        svg {
          height: 2rem;
          width: auto;
        }
      }

      p {
        opacity: 0.5;
      }
    }
  }

  &.has-no-shadow {
    box-shadow: none;
  }

  &-hint {
    padding: var(--ts-app-offset) var(--ts-app-offset-l);
    font-size: var(--ts-app-font-size-s);
    display: flex;
    align-items: center;

    .box-hint-logo {
      display: flex;
      align-items: center;
      gap: var(--ts-app-offset-s);
      margin-left: auto;
    }
  }
}

.under-box {
  padding-top: var(--ts-app-gap);
  display: flex;
  flex-wrap: wrap;
  gap: var(--ts-app-gap);
  container-type: inline-size;

  //& > * {
  //  flex: 1 1 45%;
  //  min-width: 25rem;
  //}

  .under-box-right {
    margin-left: auto;
  }

  @container (max-width: 480px) {
    & > * {
      flex: 1 1 100%;
      min-width: auto;
    }
  }
}

a {
  color: var(--ts-app-color-primary);
}

strong {
  font-weight: var(--ts-app-font-weight-bold);
}

em {
  font-style: italic;
}

.pagination-debug {
  display: flex;
  align-items: center;

  & > div {
    flex: 1;
    text-align: center;
  }
}

.unkown-link {
  cursor: pointer;
  color: var(--ts-app-color-primary);
  display: inline-flex;
  gap: calc(var(--ts-app-offset) / 2);
  white-space: nowrap;
  align-items: center;

  svg {
    height: 1.6rem;
    width: auto;
    position: relative;
    top: -0.2rem;
  }

  &:hover {
    color: var(--ts-app-color-primary-hover);
  }
}

.text-primary {
  color: var(--ts-app-color-primary);
}

.text-m {
    font-size: var(--ts-app-font-size-m);
}

.text-14 {
    font-size: var(--ts-app-font-size-14);
}

.text-base-light {
  color: var(--ts-app-color-base-light);
}

#cart-portal {
  padding-top: var(--ts-app-gap);
  display: none;

  &.cart-portal-rent {
    display: block;

    & > button {
      margin-left: auto;
      width: auto;
    }
  }

  & > button {
    width: 100%;
  }
}

@media screen and (max-width:927px)  {
  .left .box-cta > .btn:not(.always-visible),
  .left .box-header .box-header-right {
    display: none;
  }

  .left .box-cta:has(.always-visible) {
    flex-wrap: wrap;

    > .btn.always-visible {
      width: 100%;
    }
  }

  #cart-portal {
    display: block;

    &.cart-portal-rent {
      & > button {
        width: 100%;
      }
    }
  }
}

.ml-auto {
  margin-left: auto;
}

.error-box {
  background: var(--ts-app-color-error);
  color: var(--ts-app-color-base);
  padding: var(--ts-app-offset-l);
  border-radius: var(--ts-app-border-radius);
  margin-bottom: var(--ts-app-offset-l);
}

.ui-hidden {
  display: none;
}

.cart-cta {
  padding-top: var(--ts-app-offset-l);

  .box-legal {
    color: var(--ts-app-color-base-light);
  }
}

.box-legal {
  font-size: var(--ts-app-font-size-m);
  padding-top: var(--ts-app-gap);
}
